import Image from 'next/image';
import React from 'react';
import 'twin.macro';

import { rgbToCssColor } from '@chartsy/shared/utils';

import { Scalars } from '../generatedGraphql';

interface ShopIconProps {
  className?: string;
  color: Scalars['_rgb_int'];
  name: string;
  src: string;
}

const ShopIcon = ({ className, color, name, src }: ShopIconProps) => (
  <div
    className={className}
    css={{ borderColor: rgbToCssColor(color) }}
    tw="relative rounded-full flex flex-none overflow-hidden bg-gray-200 border-2 sm:border-width[3px]"
  >
    <Image alt={`${name} shop icon`} layout="fill" objectFit="fill" src={src} />
  </div>
);

export default ShopIcon;
