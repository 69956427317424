import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import 'twin.macro';

import { PathResources } from '@chartsy/shared/utils';

import { CollectionProductFragment, Scalars } from '../generatedGraphql';
import formatPrice from '../utils/formatPrice';
import sortedUniqueThreads from '../utils/sortedUniqueThreads';
import ResourceLink from './ResourceLink';
import ShopIcon from './ShopIcon';
import ThemeImage from './ThemeImage';
import ThreadsBadge from './ThreadsBadge';

const ProductListItem = ({
  className,
  disableLink = false,
  imageCss,
  product: {
    chart_public: chartPublic,
    themes_public: themes,
    name,
    price,
    shop,
    slug,
  },
}: {
  className?: string;
  disableLink?: boolean;
  imageCss?: Interpolation<Theme>;
  product: CollectionProductFragment;
}) => {
  const firstTheme = themes[0];

  if (!firstTheme) {
    // TODO log error
    return <div>Missing theme for product {slug}</div>;
  }

  const threads = sortedUniqueThreads(firstTheme.themes_stitch_groups_threads);
  const to: PathResources = { productSlug: slug, type: 'product' };

  // Hasura is treating views as nullable, but we know this isn't
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return (
    <ResourceLink
      disableLink={disableLink}
      to={to}
      className={className}
      tw="flex flex-col"
    >
      <div>
        <div css={imageCss} tw="relative flex">
          <ThemeImage
            name={firstTheme.name!}
            // Casting due to Hasura view columns being all nullable
            chartDimensions={chartPublic as Scalars['dimensions']}
            themePublic={firstTheme}
            tw="aspect-h-1 aspect-w-1 rounded-lg sm:rounded-xl md:rounded-2xl overflow-hidden"
          />
          <ThreadsBadge
            fabricColor={firstTheme.fabric_color!}
            threads={threads}
            tw="absolute right-1 bottom-1 sm:(right-2 bottom-2)"
          />
        </div>
      </div>
      <div tw="flex mt-3 md:mt-4 w-full items-center">
        <ShopIcon
          color={shop.color}
          name={shop.name}
          src={shop.icon_file.s3_url}
          tw="h-8 w-8 sm:(h-12 w-12)"
        />
        <div tw="ml-2 md:ml-3 w-full overflow-hidden">
          <div tw="flex flex-col w-full">
            <div tw="text-sm sm:text-base md:text-lg font-bold truncate">
              {name}
            </div>
            <div tw="font-bold text-sm md:text-base">{formatPrice(price)}</div>
          </div>
        </div>
      </div>
    </ResourceLink>
  );
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

export default ProductListItem;
