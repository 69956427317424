import React from 'react';
import 'twin.macro';

import { CollectionProductFragment } from '../generatedGraphql';
import ProductListItem from './ProductListItem';

interface ProductGridProps {
  className?: string;
  products: CollectionProductFragment[];
}

const ProductGrid = ({ className, products }: ProductGridProps) => (
  <ul
    className={className}
    tw="grid grid-cols-2 gap-x-4 gap-y-6 sm:(grid-cols-3 gap-x-12 gap-y-8) lg:(gap-x-24 gap-y-16)"
  >
    {products.map((product) => (
      <li key={product.id}>
        <ProductListItem product={product} />
      </li>
    ))}
  </ul>
);

export default ProductGrid;
