import {
  ProjectAndThemeThemesStitchGroupsThreadsFragment,
  PublicThemeThemesStitchGroupsThreadsFragment,
} from '../generatedGraphql';

type ThemesStitchGroupsThreadsToGroup =
  | PublicThemeThemesStitchGroupsThreadsFragment
  | ProjectAndThemeThemesStitchGroupsThreadsFragment;

export type BrandLineWithThreadsThread<
  T extends ThemesStitchGroupsThreadsToGroup
> = T['thread'] & {
  amount: number;
};

type BrandLineWithThreads<
  T extends ThemesStitchGroupsThreadsToGroup
> = T['thread']['brand_line'] & {
  threads: {
    [key: string]: BrandLineWithThreadsThread<T>;
  };
};

export default function groupThreadsByBrandLine<
  T extends ThemesStitchGroupsThreadsToGroup
>(themesStitchGroupsThreads: ThemesStitchGroupsThreadsToGroup[]) {
  const threadsByBrandLine = themesStitchGroupsThreads.reduce<{
    [key: string]: BrandLineWithThreads<T>;
  }>((result, { stitch_group: { amount }, thread }) => {
    const brandLineId = thread.brand_line.id;
    const brandLine: BrandLineWithThreads<T> = result[brandLineId] || {
      ...thread.brand_line,
      threads: {},
    };

    const threadAndAmount = brandLine.threads[thread.id] || {
      ...thread,
      amount: 0,
    };

    // eslint-disable-next-line no-param-reassign
    result[brandLineId] = {
      ...brandLine,
      threads: {
        ...brandLine.threads,
        [thread.id]: {
          ...threadAndAmount,
          amount: amount + threadAndAmount.amount,
        },
      },
    };

    return result;
  }, {});

  return Object.values(threadsByBrandLine).map((brandLineWithThreads) => ({
    ...brandLineWithThreads,
    threads: Object.values(brandLineWithThreads.threads).sort(
      (a, b) => b.amount - a.amount,
    ),
  }));
}
