import { TinyColor } from '@ctrl/tinycolor';
import React from 'react';
import tw from 'twin.macro';

import { pluralize, rgbToCssColor } from '@chartsy/shared/utils';

import { Scalars } from '../generatedGraphql';

interface ThreadBadgeProps {
  className?: string;
  fabricColor: Scalars['_rgb_int'];
  showTitle?: boolean;
  threads: { id: string; color: Scalars['_rgb_int'] }[];
}

const ThreadsBadge = ({
  className,
  fabricColor,
  showTitle = false,
  threads,
}: ThreadBadgeProps) => {
  const fabricColorCss = rgbToCssColor(fabricColor);
  const title = `${threads.length} thread ${pluralize(
    threads.length,
    'color',
  )}`;

  return (
    <div
      css={{ backgroundColor: fabricColorCss }}
      className={className}
      tw="flex items-center rounded-full ring-2 ring-gray-400 ring-opacity-40 margin[2px]"
    >
      {showTitle && (
        <span
          css={new TinyColor(fabricColorCss).isDark() && tw`text-white`}
          tw="ml-2 mr-1 text-sm sm:text-base"
        >
          {title}
        </span>
      )}
      <ol title={title} tw="flex flex-row-reverse">
        {threads
          .slice(0, 4)
          .reverse()
          .map(({ id, color }, index) => (
            <li
              aria-label="thread color badge"
              css={[
                {
                  borderColor: fabricColorCss,
                  backgroundColor: rgbToCssColor(color),
                  zIndex: index,
                },
                tw`h-5 w-5 sm:(h-6 w-6)`,
                tw`not-last:(-ml-1 sm:-ml-2)`,
                threads.length >= 3 && tw`not-last:(-ml-3 sm:-ml-4)`,
              ]}
              key={id}
              tw="relative rounded-full border-2"
            />
          ))}
      </ol>
    </div>
  );
};

export default ThreadsBadge;
